<template>
  <input type="button" @click="addText" :value="title" />
</template>

<script>
export default {
  name: "TextTemplateButton",
  emits: ['addTextEvent'],
  props: ["title", "text"],
  methods: {
    addText() {
      this.$emit('addTextEvent', this.text)
    }
  }
}
</script>

<style scoped>

</style>