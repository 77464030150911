<template>

  <div class="loading-indicator" v-bind:class="{hidden: !loading}">
    <div class="loading-indicator-container">
      <div class="loading-indicator-text">
        {{ message }}
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "LoadingIndicator",
  props: ['loading', 'message']
}
</script>

<style scoped>
  .loading-indicator {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    color: white;
    background-color: rgba(0,0,0,0.6);
    display: block;
    font-size: xx-large;
  }

  .hidden {
    display: none;
  }

  .loading-indicator-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .loading-indicator-text {
    background-color: #00628d;
    width: 50%;
    padding: 6rem;
    text-align: center;
  }
</style>