<template>
  <table class="patient-table self-start">
    <tbody>
    <tr>
      <td class="border-right">Patient ID</td>
      <td>#0001</td>
    </tr>
    <tr>
      <td class="border-right">G-Datum</td>
      <td>23.04.1986</td>
    </tr>
    <tr>
      <td class="border-right">Aktuelle Diagnose</td>
      <td>Schubförmige, remittierende MS</td>
    </tr>
    <tr>
      <td class="border-right">Zeitpunkt der aktuellen Diagnose</td>
      <td>01.12.2014</td>
    </tr>
    <tr style="border-bottom: 0px solid darkgrey">
      <td class="border-right">Bildungsgrad</td>
      <td>Abitur</td>
    </tr>
    </tbody>
  </table>
  <table class="study-table">
    <thead style="font-weight: 900">
    <tr>
      <th style="" class="left-aligned-cell">Zeitstrahl/Events</th>
      <th>10.09.2021</th>
      <th>17.09.2021</th>
      <th>26.08.2022</th>
      <th>02.09.2022</th>
      <th>01.08.2023</th>
      <th style=""><input type="button" class="mt-0 action mb-0" value="Neues Datum hinzufügen"></th>
    </tr>
    </thead>
    <form>
      <tr>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Visite</td>
        <td>10.09.2021</td>
        <td class="greyed-out-cell"></td>
        <td>26.08.2022</td>
        <td class="greyed-out-cell"></td>
        <td>01.08.2023(geplant)</td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Medikament</td>
        <td>kein</td>
        <td class="greyed-out-cell"></td>
        <td>Teriflunomid</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Schub (während der letzten 12 Monate vor der Visite) (ja / nein)</td>
        <td>nein</td>
        <td class="greyed-out-cell"></td>
        <td>nein</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">SDMT</td>
        <td>nicht erhoben</td>
        <td class="greyed-out-cell"></td>
        <td>nicht erhoben</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">EDSS-Gesamtscore</td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>1</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td colspan="6" style="text-align: center; font-weight: bold">EDSS Funktionssysteme</td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Sehfunktion (Grad 0 bis 4)</td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Hirnstamm (Grad 0 bis 5)</td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Pyramidenbahn (Grad 0 bis 6)</td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td style="color: red; font-weight: bold">1</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Kleinhirn (Grad 0 bis 5)</td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Sensorium (Grad 0 bis 6)</td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Blase- und Mastdarm (Grad 0 bis 6)</td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Zerebrale Funktionen (Grad 0 bis 5)</td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Gehfähigkeit</td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td><input></td>
      </tr>
      <tr>
        <td colspan="6" style="text-align: center; font-weight: bold">MRT-Aktivitätsmonitoring</td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">MRT-Untersuchung</td>
        <td class="greyed-out-cell"></td>
        <td>17.09.2021</td>
        <td class="greyed-out-cell"></td>
        <td>02.09.2022</td>
        <td></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Biometrica-Bericht/Nr.</td>
        <td class="greyed-out-cell"><input></td>
        <td><a
            href="https://befund.jung-radiology.de/report/seriesInstanceUid/1.2.826.0.1.3680043.8.498.59728092385313712400114693986365719620">91511</a>
        </td>
        <td class="greyed-out-cell"><input></td>
        <td><a
            href="https://befund.jung-radiology.de/report/seriesInstanceUid/1.2.826.0.1.3680043.8.498.59728092385313712400114693986365719620">91510</a>
        </td>
        <td><input></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Bewertung MRT-Aktivität (ja/nein/unklar)</td>
        <td class="greyed-out-cell"></td>
        <td style="font-weight: bold; background-color: palegreen">Nein</td>
        <td class="greyed-out-cell"></td>
        <td style="font-weight: bold; background-color: palevioletred">Ja</td>
        <td></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">T2-Läsionsaktivität (Anzahl neuer und/oder vergrößerter Läsionen)
        </td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>2</td>
        <td></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Anzahl KM-anreichernder Läsionen</td>
        <td class="greyed-out-cell"></td>
        <td>-</td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Hirnvolumenverlustrate (%/Jahr)</td>
        <td class="greyed-out-cell"></td>
        <td>-0,4</td>
        <td class="greyed-out-cell"></td>
        <td>-0,4</td>
        <td></td>
      </tr>
      <tr>
        <td class="left-aligned-cell border-right">Hirnvolumenverlustrate (0:altersentsprechend; 1:grenzwertig/unklar;
          2:pathologisch)
        </td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td class="greyed-out-cell"></td>
        <td>0</td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td><input type="button" class="mt-0 action" value="Speichern"></td>
      </tr>
    </form>
  </table>
</template>

<script>
export default {
  name: "Cockpit"
}
</script>

<style scoped>
th, td {
  padding: 3px 10px 3px;
  text-align: center
}

tr {
  border-bottom: 1px solid darkgrey;
}

.greyed-out-cell {
  background-color: #424242;
}

.left-aligned-cell {
  text-align: left;
}

.border-right {
  border-right: 1px solid darkgrey;
}

input {
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
}

.patient-table {
  background-color: grey;
  color: white;
  border-collapse: collapse;
  border-radius: 25px;
  margin: 25px 25px;
  padding: 20px;
  font-size: 0.9em;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.study-table {
  background-color: grey;
  color: white;
  border-collapse: collapse;
  border-radius: 10px;
  margin: 25px 25px;
  padding: 20px;
  font-size: 0.9em;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.action {
  background-color: lightgrey !important;
  color: black !important;
}
</style>