<template>

  <h1>Reporting</h1>

  <div id="dicom-information" class="w-full flex border border rounded-lg my-3 mx-3" style="width: calc(100% - 1.5rem); margin: auto 0.75rem;">
    <div id="patient-information" class="w-1/3 text-left border-r py-2">
      <div class="caption">Patient</div>
      <div class="line w-full">
        <div id="patientName" class="key">Name / Geschlecht</div>
        <div class="value">{{report?.patientName}} / {{report?.patientSex}}</div>
      </div>
      <div class="line line w-full">
        <div id="patientId" class="key">Patient ID</div>
        <div class="value">{{report?.patientId}}</div>
      </div>
      <div class="line">
        <div id="patientBirthDate" class="key">Geburtsdatum</div>
        <div class="value">{{birthDate}}</div>
      </div>
    </div>
    <div id="study-information" class="w-1/3 text-left border-r py-2 mr-3">
      <div class="caption">Studie</div>
      <div class="line">
        <div id="studyDate" class="key">Studiendatum</div>
        <div class="value">{{studyDate}}</div>
      </div>
      <div class="line">
        <div id="accessionNumber" class="key">Accession Number</div>
        <div class="value">{{report?.accessionNumber}}</div>
      </div>
      <div class="line">
        <div id="referringPhysician" class="key">Überweisender Arzt</div>
        <div class="value">{{report?.referringPhysicianName}}</div>
      </div>
    </div>
    <div id="report-status" class="w-1/3 text-left py-2">
      <div class="caption">Report Status</div>
      <div class="line">
        <div id="completionFlag" class="key">Fertigstellungskennzeichen</div>
        <div class="value">{{report?.completionFlag}}</div>
      </div>
      <div class="line">
        <div id="verificationFlag" class="key">Verifikationskennzeichen</div>
        <div class="value">{{report?.verificationFlag}}</div>
      </div>
    </div>
  </div>

  <div class="report-content mx-3">
    <div id="technology" class="my-3">
      <label for="technology-text"  style="float: left" class="my-3">Technik <a href="#" class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                                                                               data-bs-toggle="tooltip" title="Verwendete Technologie wird mit in Zwischenablage kopiert."><input type="checkbox" class="action my-3" name="importTechnology" id="importTechnology" value="importTechnology" v-model="userSettings.importTechnology" @change="updateUser"></a> </label>
      <br>
      <textarea id="technology-text" name="report-text" rows="4"
                class="dark:bg-gray-500 rounded-md px-3 py-2 border border-gray-300 w-full"
                autofocus v-model="report.technology"></textarea>
      <br>
    </div>

    <div id="report" class="my-3">
      <label for="findings-text" style="float: left" class="my-3">Beschreibung <a href="#"
                                                                                  class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                                                                                  data-bs-toggle="tooltip"
                                                                                  title="Beschreibung wird mit in Zwischenablage kopiert."><input
          type="checkbox" class="action" name="result" id="result" value="result" v-model="userSettings.importResults"
          @change="updateUser"></a></label>
      <br>
      <div v-show="currentUserIsReporter">
      <textarea id="fixedFindings-text" name="report-text" rows="6"
                class="dark:bg-gray-500 rounded-md px-3 py-2 border border-gray-300 w-full"
                autofocus v-model="fixedFindings" disabled="true"></textarea>
        <br>
      </div>
      <textarea id="findings-text" name="report-text" rows="6"
                class="dark:bg-gray-500 rounded-md px-3 py-2 border border-gray-300 w-full"
                autofocus v-model="report.findings"></textarea>
      <br>
    </div>

    <div id="template" class="my-3">
      <text-template-button v-for="(t, index) in userSettings.medicalReportButtons" :title="t.buttonName"
                            :text="t.buttonText" :key="index"
                            @add-text-event="appendTextFromTemplate(t.buttonText, 'findings')"/>
    </div>

    <div id="evaluation" class="my-3">
      <label for="evaluation-text" style="float: left" class="my-3">Beurteilung <a href="#"
                                                                                   class="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out"
                                                                                   data-bs-toggle="tooltip"
                                                                                   title="Beurteilung wird mit in Zwischenablage kopiert.">
        <input type="checkbox" class="action" name="importEvaluation" id="importEvaluation" value="importEvaluation"
               v-model="userSettings.importEvaluation" @change="updateUser"></a></label>
      <br>
      <div v-show="currentUserIsReporter">
      <textarea id="fixedEvaluation-text" name="report-text" rows="6"
                class="dark:bg-gray-500 rounded-md px-3 py-2 border border-gray-300 w-full"
                autofocus v-model="fixedEvaluation" disabled="true"></textarea>
        <br>
      </div>
      <textarea id="evaluation-text" name="report-text" rows="6"
                class="dark:bg-gray-500 rounded-md px-3 py-2 border border-gray-300 w-full"
                autofocus v-model="report.evaluation"></textarea>
      <br>
    </div>

    <div v-show="currentUserIsReporter" id="evaluation-templates" class="my-3">
      <text-template-button v-for="(t, index) in userSettings.medicalReportButtons" :title="t.buttonName"
                            :text="t.buttonText" :key="index"
                            @add-text-event="appendTextFromTemplate(t.buttonText, 'evaluation')"/>
    </div>

    <div id="reportImages" class="flex flex-row w-full flex-wrap justify-center">
      <div v-for="(imageBytes, index) in report.imageBytes" :key="index">
        <img :src="'data:'+ report.imageBytesFormat + ';base64,'+ imageBytes"
             class="min-w-0 m-4 rounded-md" style="height: 23rem"/>
        <div v-if="userSettings.imagePreviewType === 'PNG'" class="flex justify-center items-center">
          <input type="button" class="mt-0 action" name="copyToClipboard" value="Bild kopieren"
                 @click="copyToClipboardImage('data:'+ report.imageBytesFormat + ';base64,'+ report.imageBytes[index])">
        </div>
      </div>
    </div>
  </div>

  <div class="actions" style="width: calc(100% - 1.5rem); margin: auto 0.75rem; overflow: hidden;">
    <div style="float: left;">
      <input type="button" class="action" name="preview" value="Vorschau" @click="preview">
      <input type="button" id = "saveDraft" class="action" name="saveDraft" value="Entwurf speichern" @click="saveDraft">
    </div>
    <div style="float: right">
      <router-link v-show="currentUserIsSystemAdmin" to="/cockpit"><input type="button" class="action" value="Cockpit">
      </router-link>
      <input v-if="userSettings.exportDefinitions?.length" type="button" class="action" name="export"
             value="Exportieren"
             @click="exportReport">
      <input type="button" class="action" name="copy" value="Kopieren" @click="copyToClipboard">
      <input type="button" class="action" name="finalize" value="Fertigstellen" @click="finalize">
      <input type="button" v-show="currentUserIsReporter" class="action" value="In PACS öffnen" @click="openInPacs"/>
    </div>
  </div>

  <div class="actions" style="width: calc(100% - 2.4rem); margin: auto 1.2rem; overflow: hidden;">
    <label for="aiResultOnly" style="font-size: 0.9rem; margin-right: 0.5rem">Gespeicherte Reports/ Entwürfe ignorieren</label>
    <input type="checkbox" class="action" name="aiResultOnly" id="aiResultOnly" value="aiResultOnly" v-model="aiResultOnly" @change="aiResultOnlyChanged">
  </div>

  <div v-show="showPreview" id="previewModal" style="" class="align-bottom bg-white rounded-lg text-left overflow-hidden shadow-2xl transform transition-all sm:my-8 sm:align-middle w-full h-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="modal-container bg-white px-4 pt-4 pb-4 h-full">
      <div class="mt-3 text-center w-full">
        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
          <b>Report Preview</b>
        </h3>
      </div>
      <div class="preview-container w-full py-3" style="height: calc(100% - 6rem)">
        <iframe class="preview-iframe" id="preview"></iframe>
      </div>
      <div class="w-full" style="float: right; text-align: right;">
        <button type="button" @click="showPreview = false" class="m-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Close
        </button>
      </div>
    </div>
  </div>

  <div v-show="loading" class="loading-container">
    <div class="loading-animation">
      Loading
    </div>
  </div>

  <loading-indicator :loading="loadingReportData" message="Loading case..."></loading-indicator>
  <loading-indicator :loading="finalizeReport" message="Finalizing report..."></loading-indicator>
  <loading-indicator :loading="savingDraft" message="Saving draft..."></loading-indicator>

</template>

<script>

import TextTemplateButton from "@/components/TextTemplateButton";
import LoadingIndicator from "@/components/LoadingIndicator";
import {DateFormatter} from "@/DateFormatter";

export default {
  name: "Report",
  components: {TextTemplateButton, LoadingIndicator},
  data() {
    return {
      userSettings: {
        name: "",
        importResults: null,
        importTechnology: null,
        importEvaluation: null,
        medicalReportButton: [],
        exportDefinitions: [],
        imagePreviewType: null
      },
      jobManagerIP: null,
      key: this.$route.fullPath,
      identifier: this.$route.params.identifier,
      identifierType: this.$route.params.identifierType,
      aiResultOnly: this.$route.query.aiResultOnly ? this.$route.query.aiResultOnly : false,
      report: {
        evaluation: "",
        technology: "",
        findings: "",
        imageBytes: [],
        imageBytesFormat: null,
        studyInstanceUid: "",
        patientId: ""
      },
      fixedFindings: "",
      fixedEvaluation: "",
      loadingReportData: true,
      finalizeReport: false,
      savingDraft: false,
      loading: false,
      showPreview: false
    }
  },
  watch: {
    key() {
      this.init()
    }
  },
  beforeRouteUpdate(to, from) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, given a route with params `/users/:id`, when we
    // navigate between `/users/1` and `/users/2`, the same `UserDetails` component instance
    // will be reused, and this hook will be called when that happens.
    // Because the component is mounted while this happens, the navigation guard has access to `this` component instance.
    console.log(to)
    console.log(from)
    this.init();
  },
  computed: {
    birthDate() {
      if (this.report?.patientBirthDate) {
        try {
          let d = new Date(this.report.patientBirthDate)
          return DateFormatter.getMonthYearStringFromDate(d)
        } catch (err) {
         return "";
        }
      }
      return ""
    },
    studyDate() {
      if (this.report?.studyDate) {
        return new Date(this.report.studyDate).toLocaleDateString()
      }
      return ""
    },
    username() {
      return this.$store.state.user.username
    },
    currentUserIsSystemAdmin() {
      return (this.$store.state.user.roles.indexOf("ROLE_SYSTEM_ADMIN") !== -1)
    },
    currentUserIsReporter() {
      return (this.$store.state.user.roles.indexOf("ROLE_REPORTER") !== -1)
    }
  },
  methods: {
    copyToClipboardImage(url) {
     fetch(url)
      .then(async res => res.blob())
      .then(blob => navigator.clipboard.write([new window.ClipboardItem({[blob.type]: blob})]))
    },

    update() {
      console.log(this.user)
      let data = JSON.parse(JSON.stringify(this.user))
      delete data.permissions
      this.$http
          .put('/api/v1/user/' + this.user.id, data)
          .then(response => {
            console.log(response)
            this.user = response.data
            alert("Successfully updated user.")
          })
          .catch(reason => {
            console.log(reason)
            alert("Error updating user!")
          })
    },
    updateUser() {
      let data = {
        signerName: this.userSettings.signerName,
        name: this.userSettings.name,
        darkMode: this.userSettings.darkMode,
        importResults: this.userSettings.importResults,
        importTechnology: this.userSettings.importTechnology,
        importEvaluation: this.userSettings.importEvaluation
      }
      this.$http
          .post('/api/v1/user/' + this.username, data)
          .then(response => {
            console.log("User:")
            console.log(response)
            //alert("Successfully updated user information.")
          })
          .catch(reason => {
            console.log(reason)
            alert("Error updating user information!")
          })
    },
    aiResultOnlyChanged(a) {
      console.log(a)
      let result = confirm("The page will be reloaded and you will lose your changes. Continue?")
      if (result === true) {
        // Reload page, but set query parameter
        console.log(this.aiResultOnly)
        this.$router.push({
          name: "report",
          params: {
            identifier: this.identifier.toString(),
            identifierType: this.identifierType.toString()
          },
          query: {
            aiResultOnly: this.aiResultOnly.toString()
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        // Undo change
        this.aiResultOnly = !this.aiResultOnly
      }


    },
    appendTextFromTemplate(text, field) {
      let tmp = this.report[field]
      console.log("TMP: " + tmp)

      if (tmp === undefined) {
        tmp = ""
      }

      console.log("is defined")
      if (!tmp.endsWith(" ") && !tmp.endsWith("\n") && !tmp.endsWith("\t") && tmp.length > 0) {
        console.log("adding text")
        tmp += " " + text
      } else {
        tmp += text
      }
      this.report[field] = tmp;
    },
    toggle() {
      this.loading = !this.loading;
    },
    preview() {
      let report = this.report;
      this.$http
        .post('/api/v1/report/getPreview', {report: report}, {responseType: 'blob'})
        .then(response => {
          console.log(response)
          let blob = new Blob([response.data], {type: 'application/pdf'});
          let url = window.URL.createObjectURL(blob);
          document.getElementById("preview").setAttribute('src', url);
          this.showPreview = true;
        }).catch(reason => {
          console.log(reason)
      })
      this.$http
          .put('/api/v1/report/preview', {
            report: this.report
          })
          .then(response => {
            console.log(response)
          }).catch(reason => {
        console.log(reason)
      })
    },
    openInPacs(){
      window.location.href='jivexrv:rv.startmode=load&rv.load.data.type=study&rv.load.data='+this.report.studyInstanceUid;
    },
    saveDraft() {
      this.savingDraft = true
      let data = {
        identifierType: this.identifierType,
        identifier: this.identifier,
        report: {...this.report}
      }
      delete data.report.imageBytes
      this.$http
          .put('/api/v1/report/save', data)
          .then(response => {
            console.log(response)
            this.savingDraft = false
            alert("Entwurf erfolgreich gespeichert.")
          }).catch(reason => {
        console.log(reason)
        this.savingDraft = false
        alert("Fehler beim Speichern des Entwurfs.")
      })
    },
    finalize() {
      const response =  confirm("Sind Sie sicher, dass Sie den Report finalisieren wollen? Danach kann er nicht weiter bearbeitet werden.")
      if(response) {
        let data = {
          identifierType: this.identifierType,
          identifier: this.identifier,
          report: {...this.report}
        }
        delete data.report.imageBytes
        this.$http
            .put('/api/v1/report/finalize', data)
            .then(response => {
              console.log(response)
              this.finalizeReport = false
              alert("Der Bericht wurde erfolgreich finalisiert.")
              this.init()
            }).catch(reason => {
          console.log(reason)
          this.finalizeReport = false
          alert("Error finalizing report.")
        })
      }
    },
    exportReport() {
      if (!this.userSettings.exportDefinitions?.length) {
        return
      }
      let exportDefinition = this.userSettings.exportDefinitions[0]
      function handleErrors(response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      }
      if(exportDefinition.type === "GET"){
        fetch(eval(exportDefinition.url), {method: "GET"})
            .then(handleErrors)
            .then(response => {
              response.text().then(body => alert(body))
            })
            .catch(reason => {
              console.log(reason)
              alert(reason)
            })
      }
      else if(exportDefinition.type === "POST"){
        fetch(eval(exportDefinition.url), {method: "POST"})
            .then(handleErrors)
            .then(response => {
              response.text().then(body => alert(body))
            })
            .catch(reason => {
              console.log(reason)
              alert(reason)
            })
      }
    },

    copyToClipboard() {
      let parts = []
      if (this.userSettings.importTechnology) {
        parts = ["Technik", this.report?.technology]
      }
      if (this.userSettings.importResults) {
        parts.push("Befund", this.report?.findings)
      }
      if (this.userSettings.importEvaluation) {
        parts.push("Beurteilung", this.report?.evaluation)
      }
      navigator.clipboard.writeText(parts.join("\n\n")).then(() => {
        console.log("Copied to clipboard successfully!");
        this.$store.commit('toast/addToast', {
          title: 'Copied',
          message: 'Copied report content to clipboard.',
          type: 'info'
        })
      }, function () {
        console.log("Unable to write to clipboard. :-(");
      });
      let data = {
        report: {...this.report}
      }
      delete data.report.imageBytes
      this.$http
          .put('/api/v1/report/copy', data)
          .then(response => {
            console.log(response)
          }).catch(reason => {
        console.log(reason)
      })
    },
    init() {
      this.loadingReportData = true;

      let data = {
        identifier: this.identifier,
        identifierType: this.identifierType,
        aiResultOnly: this.aiResultOnly,
        report: undefined
      }

      console.log(data)

      this.$http
          .post('/api/v1/report/fetch/', data)
          .then(response => {
            if (response.status && response.status === 200) {
              this.report = response.data
              if (this.currentUserIsReporter && this.report.state === "NEW") {
                this.fixedFindings = this.report.findings
                this.report.findings = ""
                this.fixedEvaluation = this.report.evaluation
                this.report.evaluation = ""
              }
              console.log(response.data)
              if (this.report.state === "DRAFT") {
                this.$store.commit('toast/addToast', {
                  title: 'Draft loaded',
                  message: 'The content of this report was loaded from a former saved draft.',
                  type: 'info'
                })
              }
              if (this.report.state === "FINALIZED") {
                document.getElementById("technology-text").disabled = true
                document.getElementById("findings-text").disabled = true
                document.getElementById("evaluation-text").disabled = true
                document.getElementById("saveDraft").disabled = true

                this.$store.commit('toast/addToast', {title: 'Report loaded', message: 'The content of this report was loaded from a former finalized draft. Finalized reports can not be edited again.', type: 'info'})

              }
              if (this.report.state === "AUTHORIZED") {
                this.$store.commit('toast/addToast', {title: 'Report loaded', message: 'The content of this report was loaded from a former authorized report.', type: 'info'})
              }
              this.loadingReportData = false;
            } else if (response.status && response.status === 401) {
              // handled by axios interceptor - don't show any message
            } else {
              console.log("fetchReport response status != 200:")
              console.log(response)
              this.loadingReportData = false;
            }
          }).catch(reason => {
        if (reason.status === 401) {
          // handled by axios interceptor
        } else {
          console.log(reason)
          alert("Es konnten keine Dicom-Daten zu Ihrer Anfrage gefunden werden.")
          this.loadingReportData = false;
        }
      });
    },
    loadUserSettings() {
      this.$http
          .get('/api/v1/user/' + this.username)
          .then(response => {
            console.log("User:")
            console.log(response)
            this.userSettings = response.data
            this.userSettings.medicalReportButtons = this.userSettings.medicalReportButtons.sort((a, b) => (a.id > b.id) ? 1 : -1)
          })
          .catch(reason => {
            console.log(reason)
            alert("Error loading user!")
          })
    },
  },
  mounted() {
    this.init();
    this.loadUserSettings();
  }
}
</script>

<style scoped>

  .loading-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0.5,0.5,0.5,0.5);
  }

  .loading-animation{
    text-align: center;
    width: 10vw;
    min-width: 10rem;
    height: 8vh;
    padding: 1rem;
    min-height: 3rem;
    margin-top: 45vh;
    margin-left: auto;
    margin-right: auto;
    background-color: lightgrey;
  }

  #previewModal {
    width: calc(100% - 4rem);
    border: 1px solid #bfbfbf;
    height: 92vh;
    position: fixed;
    top: 1rem;
    left: 2rem;
  }

  .caption {
    margin: auto 0.5rem 0.75rem 0.5rem;
    text-align: left;
    font-weight: bold;
    width: 100%;
    padding: 0;
  }

  .line {
    margin: auto auto 0.1rem auto;
  }

  .line > .key {
    margin: auto 0.5rem;
    width: 100%;
    font-weight: bold;
  }

  .line > .value {
    margin: auto 0.8rem auto 0.5rem;
    width: 100%;
    min-height: 24px;
    overflow-wrap: break-word;
  }

  .preview-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .preview-iframe {
    flex-grow: 1;
    width: 100%;
    height: 100%;
  }

  .action {
    background-color: lightgrey !important;
    color: black !important;
  }

</style>